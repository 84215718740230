import React, { useCallback, useMemo, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { Checkbox, HStack } from '@adc/parallax-component-library';

import { RegisterProps } from 'Components/register/CreateNewUser';
import {
  CountryBottomSheetModal,
  Footer,
  Form,
  Header,
  LogoActionBar,
  Main,
  RichText,
  ScrollContainer,
  SelectorInput,
} from 'Components/utility';
import { NavigationProps } from 'Components/wizard';

import { RootState } from 'Reducers/index';
import { REGISTER_CLEAR } from 'Reducers/register/types';

import { setUrls } from 'Utilities/dataHelpers';
import i18n from 'Utilities/i18n';
import { store } from 'Utilities/store';

const mapStateToProps = ({ config: { supportedCountries } }: RootState) => {
  return {
    supportedCountries,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & NavigationProps<Register> & RegisterProps;

const RegisterConfirmCountry: React.FC<Props> = ({ next, back, values, supportedCountries }) => {
  const [selectedCountry, setSelectedCountry] = useState(values.country);
  const [confirmCountryChecked, setConfirmCountryChecked] = useState(false);
  const [isBottomSheetModalOpen, setIsBottomSheetModalOpen] = useState(false);

  const onSubmit = useCallback(() => {
    const { language } = store.getState().lang;

    const register: Register = {
      ...values,
      country: selectedCountry,
      language,
    };

    const config = store.getState().config;

    setUrls(config, selectedCountry);

    next(register);
  }, [selectedCountry, next, values]);

  const onCancelBottomSheetModal = useCallback(() => {
    setIsBottomSheetModalOpen(false);
  }, []);

  const onSelectCountry = useCallback((selectedCountry: string) => {
    setSelectedCountry(selectedCountry);
    setIsBottomSheetModalOpen(false);
  }, []);

  const handleOnBack = useCallback(() => {
    store.dispatch({ type: REGISTER_CLEAR });
    back();
  }, [back]);

  const selectorValue = useMemo(() => {
    return selectedCountry
      ? i18n.t<string>(`Global.microcopy.countries.${selectedCountry.toUpperCase()}`)
      : '';
  }, [selectedCountry]);

  return (
    <Main>
      <LogoActionBar
        actionButtonTestID="ConfirmCountry.action-go-back"
        titleTestID="ConfirmCountry.actionTitle"
        title={i18n.t('ConfirmCountry.title')}
        onBack={handleOnBack}
      />
      <ScrollContainer>
        <Header
          titleTestID="ConfirmCountry.title"
          subtitleTestID="ConfirmCountry.subtitle"
          title={i18n.t<string>('ConfirmCountry.title')}
          subtitle={i18n.t<string>('ConfirmCountry.subtitle')}
        />
        <Form>
          <SelectorInput
            testID="ConfirmCountry"
            label={i18n.t('ConfirmCountry.content.selectCountry.primaryText')}
            placeholder={i18n.t('ConfirmCountry.content.selectCountry.secondaryText')}
            aria-label={i18n.t('ConfirmCountry.content.selectCountry.primaryText')}
            value={selectorValue}
            onClick={() => setIsBottomSheetModalOpen(true)}
          />
          <HStack padding="$4">
            <Checkbox
              checked={confirmCountryChecked}
              onChange={() => setConfirmCountryChecked(!confirmCountryChecked)}
              marginRight="$4"
              testID="ConfirmCountry.checkBox"
            />
            <RichText
              testID="ConfirmCountry.selectCountry-bodyText"
              text={i18n.t<string>('ConfirmCountry.content.selectCountry.bodyText')}
            />
          </HStack>
        </Form>
      </ScrollContainer>
      <Footer
        buttonText={i18n.t<string>('Global.microcopy.common.next')}
        onButtonSubmit={onSubmit}
        buttonTestID="ConfirmCountry.next-btn"
        isButtonDisabled={!confirmCountryChecked || !selectedCountry}
      />
      <CountryBottomSheetModal
        isOpen={isBottomSheetModalOpen}
        onCancel={onCancelBottomSheetModal}
        onDone={onSelectCountry}
        selectedCountry={selectedCountry}
        title={i18n.t('ConfirmCountry.content.selectCountry.primaryText')}
        countries={supportedCountries}
      />
    </Main>
  );
};

export default connector(RegisterConfirmCountry);
