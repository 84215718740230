import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { BottomSheetModal } from '@adc-polaris-component-library/component-library';
import { Divider, RadioListItem, Stack } from '@adc/parallax-component-library';

import { Button } from 'Components/utility';

import i18n from 'Utilities/i18n';

import { RootState } from 'src/reducers';

type CountryBottomSheetModalProps = {
  isOpen: boolean;
  selectedCountry?: string;
  onCancel?: () => void;
  onDone: (selectedCountry: string) => void;
  title: string;
  countries: string[];
};

const mapStateToProps = ({ scrollPage: { mainSize } }: RootState) => {
  return {
    mainSize,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & CountryBottomSheetModalProps;

const CountryBottomSheetModal: React.FC<Props> = ({
  isOpen,
  onCancel,
  onDone,
  mainSize,
  selectedCountry,
  title,
  countries,
}) => {
  const [selectedItem, setSelectedItem] = useState('');

  useEffect(() => {
    if (selectedCountry && isOpen) {
      setSelectedItem(selectedCountry);
    }
  }, [selectedCountry, isOpen]);

  const orderedCountryList: { code: string; name: string }[] = useMemo(() => {
    return countries
      .filter((country) => i18n.exists(`Global.microcopy.countries.${country.toUpperCase()}`))
      .map((country) => ({
        code: country.toUpperCase(),
        name: i18n.t(`Global.microcopy.countries.${country.toUpperCase()}`),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [countries]);

  const handleCancelAction = useCallback(() => {
    setSelectedItem('');
    onCancel && onCancel();
  }, [onCancel]);

  return (
    <BottomSheetModal
      isOpen={isOpen}
      headerTitle={title}
      nativeID="CountryBottomSheetModal"
      titleColorToken="text.title"
      leftActionButton={
        onCancel ? (
          <Button
            variant="tertiary"
            testID="CountryBottomSheetModal.cancel-btn"
            label={i18n.t<string>('Global.microcopy.common.cancel')}
            aria-label={i18n.t<string>('Global.microcopy.common.cancel')}
            onPress={handleCancelAction}
          />
        ) : (
          <div />
        )
      }
      rightActionButton={
        <Button
          variant="tertiary"
          testID="CountryBottomSheetModal.done-btn"
          label={i18n.t<string>('Global.microcopy.common.done')}
          aria-label={i18n.t<string>('Global.microcopy.common.done')}
          onPress={() => {
            if (selectedItem) {
              onDone(selectedItem);
            }
          }}
        />
      }
    >
      <div
        style={{
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          maxHeight: mainSize / 2,
        }}
      >
        {orderedCountryList.map(({ code, name }) => {
          return (
            <Stack key={code}>
              <RadioListItem
                key={code}
                title={name}
                aria-label={name}
                disabled={false}
                selected={selectedItem === code}
                onPress={() => setSelectedItem(code)}
                testID={`Country.${code}`}
              />
              <Divider marginTop="$2" borderColor="$neutral.40" />
            </Stack>
          );
        })}
      </div>
    </BottomSheetModal>
  );
};

const ConnectedCountryBottomSheetModal = connector(CountryBottomSheetModal);

export { ConnectedCountryBottomSheetModal as CountryBottomSheetModal };
