import React, { useEffect, useMemo, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { BottomSheetModal } from '@adc-polaris-component-library/component-library';
import { Picker } from '@adc/parallax-component-library';

import { Button } from 'Components/utility';

import i18n from 'Utilities/i18n';

import { RootState } from 'src/reducers';

type GenderPickerProps = {
  isOpen: boolean;
  selectedGender?: string;
  onDone: (selectedCountry: string) => void;
};

const mapStateToProps = ({ config, scrollPage: { mainSize } }: RootState) => {
  return {
    supportedCountries: config.supportedCountries,
    mainSize,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & GenderPickerProps;

const GenderPicker: React.FC<Props> = ({ isOpen, onDone, mainSize, selectedGender }) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  const genders = useMemo(
    () => [
      i18n.t<string>('Global.microcopy.genderOptions.female'),
      i18n.t<string>('Global.microcopy.genderOptions.male'),
      i18n.t<string>('Global.microcopy.genderOptions.other'),
    ],
    []
  );

  useEffect(() => {
    if (selectedGender && isOpen) {
      const index = genders.findIndex((gender) => gender === selectedGender);

      setSelectedItemIndex(index);
    }
  }, [selectedGender, isOpen, genders]);

  return (
    <BottomSheetModal
      isOpen={isOpen}
      headerTitle="Gender"
      testID="GenderPicker"
      titleColorToken="text.title"
      rightActionButton={
        <Button
          variant="tertiary"
          testID="GenderPicker.done-btn"
          label={i18n.t<string>('Global.microcopy.common.done')}
          aria-label={i18n.t<string>('Global.microcopy.common.done')}
          onPress={() => {
            const gender = genders[selectedItemIndex];

            onDone(gender);
          }}
        />
      }
    >
      <div
        style={{
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          maxHeight: mainSize / 2,
        }}
      >
        <Picker>
          <Picker.Wheel
            items={genders}
            ariaLabel={i18n.t(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.gender.placeholder'
            )}
            onSelect={(selectedIndex) => {
              setSelectedItemIndex(selectedIndex);
            }}
            initialSelectedIndex={selectedItemIndex}
          />
        </Picker>
      </div>
    </BottomSheetModal>
  );
};

const ConnectedGenderPicker = connector(GenderPicker);

export { ConnectedGenderPicker as GenderPicker };
