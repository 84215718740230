import { urls } from 'Utilities/dataHelpers';
import * as log from 'Utilities/log';
import mediator from 'Utilities/mediator';

export const registerPatient = (register: Register, app: string) => {
  return new Promise<void>((resolve, reject) => {
    mediator
      .request<ApiResponse>('api:invoke', {
        method: 'POST',
        url: urls.libreApiCountryUrl + 'v1/patients',
        headers: {
          'X-User-Agent': app,
        },
        data: {
          Email: register.email,
          Password: register.password,
          Country: register.country,
          FirstName: register.firstName,
          LastName: register.lastName,
          DateOfBirth: register.dateOfBirth,
          Language: register.language,
          Gender: register.gender,
          BirthCountry: register.birthCountry,
          BirthZipCode: register.birthZipCode,
          GuardianFirstName: register.guardianFirstName,
          GuardianLastName: register.guardianLastName,
          consents: register.consents,
        },
      })
      .then(() => {
        resolve();
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};
