import React, { memo, useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { VStack } from '@adc/parallax-component-library';
import { format, parseISO } from 'date-fns';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { RegisterProps } from 'Components/register/CreateNewUser';
import {
  CountryBottomSheetModal,
  DateInput,
  Footer,
  Form,
  Header,
  LogoActionBar,
  Main,
  ScrollContainer,
  SelectorInput,
} from 'Components/utility';
import DatePicker from 'Components/utility/DatePicker';
import { GenderPicker } from 'Components/utility/GenderPicker';
import { Input } from 'Components/utility/Input';
import { NavigationProps } from 'Components/wizard';

import { REGISTER } from 'Reducers/register/types';

import { validateFrenchZipCode } from 'Utilities/dataHelpers';
import i18n from 'Utilities/i18n';
import log from 'Utilities/log';
import { store } from 'Utilities/store';

import { RootState } from 'src/reducers';

const mapStateToProps = ({ config: { adultAges, countries } }: RootState) => {
  return {
    adultAges,
    countries,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & NavigationProps<Register> & RegisterProps;

const RegisterPersonalInfo: React.FC<Props> = ({
  back,
  validateNameLength,
  validateIsMinor,
  validateDOBAndDisplayError,
  handleClickSignIn,
  showFrenchZipCodeError,
  values: currentValues,
  adultAges,
  currentStep,
  countries,
}) => {
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [isGenderPickerVisible, setIsGenderPickerVisible] = useState(false);
  const [isPlaceOfBirthBottomSheetModalOpen, setIsPlaceOfBirthBottomSheetModalOpen] =
    useState(false);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(
        i18n.t<string>(
          'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.firstName.errors.required'
        )
      )
      .trim(),
    lastName: Yup.string()
      .required(
        i18n.t<string>(
          'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.lastName.errors.required'
        )
      )
      .trim(),
    dateOfBirth: Yup.string().required(
      i18n.t<string>(
        'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.dob.errors.required'
      )
    ),
    ...(currentValues.country === 'FR' && {
      gender: Yup.string().required(),
      birthCountry: Yup.string().required(),
      birthZipCode: Yup.string().when('birthCountry', {
        is: 'FR',
        then: Yup.string().required(
          i18n.t<string>(
            'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.birthplaceZipCode.errors.required'
          )
        ),
        otherwise: Yup.string().notRequired(),
      }),
    }),
  });

  const onSubmit = useCallback(
    async (values: Register) => {
      try {
        const isValidNameLength = validateNameLength(values.firstName, values.lastName);

        if (!isValidNameLength) {
          return;
        }

        if (values.birthCountry === 'FR') {
          const isValidFrenchZipCode = validateFrenchZipCode(values.birthZipCode);

          if (!isValidFrenchZipCode) {
            showFrenchZipCodeError();
            return;
          }
        }

        const register: Register = {
          ...currentValues,
          ...values,
        };

        const isMinor = validateIsMinor(
          values.dateOfBirth,
          adultAges[values.country.toUpperCase()]
        );

        store.dispatch({
          type: REGISTER,
          register: { ...register, isMinor, currentStep: currentStep + 1 },
        });
      } catch (err) {
        log.error(err);
      }
    },
    [
      validateNameLength,
      currentValues,
      validateIsMinor,
      adultAges,
      currentStep,
      showFrenchZipCodeError,
    ]
  );

  const onClickDateInput = useCallback(() => {
    setIsDatePickerVisible((state) => {
      return !state;
    });
  }, []);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    isValid,
    errors,
    touched,
    values,
  } = useFormik({
    initialValues: {
      ...currentValues,
    },
    validationSchema,
    onSubmit,
    validateOnBlur: true,
  });

  const handleOnBack = useCallback(() => {
    back({ ...values });
  }, [back, values]);

  return (
    <Main>
      <LogoActionBar
        actionButtonTestID="RegisterPersonalInfo.action-go-back"
        titleTestID="RegisterPersonalInfo.actionTitle"
        title={i18n.t<string>('CreateAccountPersonalInfo.title')}
        onBack={handleOnBack}
      />
      {isDatePickerVisible && (
        <VStack
          backgroundColor="$surface.overlay"
          position="absolute"
          zIndex={1}
          style={{
            width: '100vw',
            height: '100vh',
          }}
        />
      )}
      <ScrollContainer>
        <Header
          titleTestID="RegisterPersonalInfo.title"
          subtitleTestID="RegisterPersonalInfo.subtitle"
          title={i18n.t<string>('CreateAccountPersonalInfo.title')}
          subtitle={i18n.t<string>(
            'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.primaryText'
          )}
        />
        <Form>
          <Input
            aria-label={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.firstName.label'
            )}
            placeholder={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.firstName.placeholder'
            )}
            label={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.firstName.label'
            )}
            testID="firstName"
            inputMode="text"
            errorMessage={errors.firstName}
            isInvalid={errors.firstName && touched.firstName ? true : false}
            value={values.firstName}
            onChangeText={handleChange('firstName')}
            onBlur={handleBlur('firstName')}
            disabled={isDatePickerVisible}
          />
          <Input
            aria-label={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.lastName.label'
            )}
            placeholder={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.lastName.placeholder'
            )}
            label={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.lastName.label'
            )}
            testID="lastName"
            inputMode="text"
            errorMessage={errors.lastName}
            isInvalid={errors.lastName && touched.lastName ? true : false}
            value={values.lastName}
            onChangeText={handleChange('lastName')}
            onBlur={handleBlur('lastName')}
            disabled={isDatePickerVisible}
          />
          <DateInput
            placeholder={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.dob.placeholder'
            )}
            label={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.dob.label'
            )}
            aria-label={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.dob.label'
            )}
            isInvalid={errors.dateOfBirth && touched.dateOfBirth ? true : false}
            errorMessage={errors.dateOfBirth}
            testID="dateOfBirth"
            onBlur={handleBlur('dateOfBirth')}
            setFieldTouched={setFieldTouched}
            value={values.dateOfBirth ? format(parseISO(values.dateOfBirth), 'MM/dd/yyyy') : ''}
            disabled={isDatePickerVisible}
            onClick={onClickDateInput}
          />
          {currentValues.country === 'FR' && (
            <>
              <SelectorInput
                testID="gender"
                label={i18n.t(
                  'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.gender.label'
                )}
                placeholder={i18n.t(
                  'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.gender.placeholder'
                )}
                aria-label={i18n.t(
                  'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.gender.label'
                )}
                value={values.gender}
                onClick={() => setIsGenderPickerVisible(true)}
              />
              <SelectorInput
                testID="birthCountry"
                label={i18n.t(
                  'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.birthplace.label'
                )}
                placeholder={i18n.t(
                  'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.birthplace.label'
                )}
                aria-label={i18n.t(
                  'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.birthplace.label'
                )}
                value={
                  values.birthCountry
                    ? i18n.t<string>(
                        `Global.microcopy.countries.${values.birthCountry.toUpperCase()}`
                      )
                    : ''
                }
                onClick={() => setIsPlaceOfBirthBottomSheetModalOpen(true)}
              />
              {values.birthCountry === 'FR' && (
                <Input
                  aria-label={i18n.t<string>(
                    'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.birthplaceZipCode.label'
                  )}
                  placeholder={i18n.t<string>(
                    'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.birthplaceZipCode.placeholder'
                  )}
                  label={i18n.t<string>(
                    'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.birthplaceZipCode.label'
                  )}
                  testID="birthZipCode"
                  inputMode="numeric"
                  errorMessage={errors.birthZipCode}
                  isInvalid={errors.birthZipCode && touched.birthZipCode ? true : false}
                  value={values.birthZipCode}
                  onChangeText={handleChange('birthZipCode')}
                  onBlur={handleBlur('birthZipCode')}
                  disabled={isDatePickerVisible}
                  maxLength={5}
                />
              )}
            </>
          )}
        </Form>
      </ScrollContainer>
      <Footer
        buttonText={i18n.t<string>(
          'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.links.next.label'
        )}
        buttonTestID="RegisterPersonalInfo.nextButton"
        buttonLinkTitle={i18n.t<string>(
          'CreateAccountPersonalInfo.content.footerSignIn.primaryText'
        )}
        buttonLinkTitleTestID="RegisterPersonalInfo.alreadyHaveAccount"
        buttonLinkText={i18n.t<string>(
          'CreateAccountPersonalInfo.content.footerSignIn.links.signIn.label'
        )}
        buttonLinkTestID="RegisterPersonalInfo.signIn"
        onButtonSubmit={handleSubmit}
        onButtonLinkClick={handleClickSignIn}
        isButtonDisabled={!values.firstName || !values.lastName || !values.dateOfBirth || !isValid}
      />
      <GenderPicker
        isOpen={isGenderPickerVisible}
        selectedGender={values.gender}
        onDone={(gender: string) => {
          console.log(gender);
          setIsGenderPickerVisible(false);
          setFieldValue('gender', gender);
        }}
      />
      <CountryBottomSheetModal
        isOpen={isPlaceOfBirthBottomSheetModalOpen}
        onDone={(country) => {
          if (country !== 'FR') {
            setFieldValue('birthZipCode', '');
          }

          setFieldValue('birthCountry', country);
          setIsPlaceOfBirthBottomSheetModalOpen(false);
        }}
        selectedCountry={values.birthCountry}
        title={i18n.t<string>(
          'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.birthplace.label'
        )}
        countries={countries}
      />
      <DatePicker
        title={i18n.t<string>(
          'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.dob.label'
        )}
        isVisible={isDatePickerVisible}
        initialDate={currentValues.dateOfBirth}
        onCancel={() => {
          setIsDatePickerVisible(false);

          if (!values.dateOfBirth) {
            setFieldError(
              'dateOfBirth',
              i18n.t<string>(
                'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.dob.errors.required'
              )
            );
          }
        }}
        onSave={(date: string, resetToDate: (date: string) => void) => {
          setIsDatePickerVisible(false);

          const isValidDate = validateDOBAndDisplayError(date);

          if (isValidDate) {
            setFieldValue('dateOfBirth', date);
            return;
          }

          resetToDate(values.dateOfBirth);
        }}
      />
    </Main>
  );
};

export default connector(memo(RegisterPersonalInfo));
